<template>
	<el-form class="page card-box" :model="agencije" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>

		<el-card shadow="hover">
			<el-row justify="space-around">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
					<el-form-item :label="$t('agencije.naziv')" prop="naziv">
						<el-input v-model="agencije.naziv" :maxLength="120"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
					<el-form-item :label="$t('agencije.aktivno')">
						<el-switch
								v-model="agencije.aktivno"
								active-value="DA"
								inactive-value="NE"
								:active-text="$t('global.aktivan')"
								:inactive-text="$t('global.neaktivan')"
								active-color="#13ce66"
								inactive-color="#ff4949"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="7" :md="6" :lg="6" :xl="3">
					<el-form-item :label="$t('agencije.maticniBroj')">
						<el-input v-model="agencije.maticni_broj" :minLength="5" :maxLength="15"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="7" :md="6" :lg="6" :xl="3">
					<el-form-item :label="$t('agencije.pib')">
						<el-input v-model="agencije.pib" :minLength="5" :maxLength="15"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="10" :md="6" :lg="4" :xl="3">
					<el-form-item :label="$t('agencije.datumPocetkaSaradnje')" class="width-full">
						<el-date-picker
								:picker-options="pickerOptions"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="dd.MM.yyyy"
								:clearable="false"
								:editable="false"
								v-model="agencije.datum_pocetka_saradnje"
								type="date">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="14" :md="10" :lg="8" :xl="6">
					<el-form-item :label="$t('agencije.sajt')">
						<el-input v-model="agencije.sajt" :maxLength="250">
							<template slot="prepend">http://</template>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="10" :md="8" :lg="6" :xl="4">
					<el-form-item :label="$t('agencije.telefon')">
						<el-input v-model="agencije.telefon" :maxLength="20"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="2">
					<el-form-item :label="$t('agencije.procenat')" class="width-full">
						<el-input-number :min="0" :max="100" v-model="agencije.procenat"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="9" :md="6" :lg="6" :xl="4">
					<el-form-item :label="$t('agencije.brojLicence')">
						<el-input v-model="agencije.broj_licence" :maxLength="45"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="9" :md="6" :lg="6" :xl="4">
					<el-form-item :label="$t('agencije.adresa')">
						<el-input v-model="agencije.adresa" :maxLength="120"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="2">
					<el-form-item :label="$t('agencije.postanskiBroj')">
						<el-input v-model="agencije.postanski_broj" :maxLength="15"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="4">
					<el-form-item :label="$t('agencije.mesto')">
						<el-input v-model="agencije.mesto" :maxLength="45"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="4">
					<el-form-item :label="$t('agencije.opstina')">
						<el-input v-model="agencije.opstina" :maxLength="45"/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
					<el-form-item :label="$t('agencije.gpsKoordinate')">
						<el-input v-model="agencije.gps_koordinate" :maxLength="20"/>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
					<el-form-item :label="$t('agencije.napomena')">
						<el-input type="textarea" v-model="agencije.napomena" :rows="6"/>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row>
				<el-col>
					<el-form-item class="labHeder">
						{{$t('agencije.banka')}}
						<el-button size="mini" type="success" icon="el-icon-plus" @click="addBanka"/>
					</el-form-item>
				</el-col>
				<el-col>
					<el-form-item>
						<el-table
								:data="agencije.banka"
								border
								stripe
								style="width: 100%">
							<el-table-column
									prop="banka"
									:label="$t('agencije.banka')">
								<template slot-scope="scope">
									<el-input v-model="scope.row.banka" :maxLength="20"/>
								</template>
							</el-table-column>
							<el-table-column
									prop="racun"
									:label="$t('agencije.racun')">
								<template slot-scope="scope">
									<el-input v-model="scope.row.racun" :maxLength="20"/>
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									width="100">
								<template slot-scope="scope">
									<el-button @click="removeArrRow(agencije.banka, scope.$index)" type="danger" icon="el-icon-delete"/>
								</template>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row>
				<el-col>
					<el-form-item class="labHeder">
						{{$t('agencije.odgovornoLice')}}
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
					<el-form-item :label="$t('agencije.ime')">
						<el-input v-model="agencije.ol_ime" :maxLength="120"/>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
					<el-form-item :label="$t('agencije.fax')">
						<el-button v-if="!agencije.ol_fax.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_fax)"/>
						<el-col v-else :key="index" v-for="(fax, index) in agencije.ol_fax">
							<el-input v-model="agencije.ol_fax[index]" :maxLength="120" class="p--b-10">
								<el-button v-if="agencije.ol_fax.length === index + 1" slot="prepend" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_fax)"/>
								<el-button slot="append" @click="removeArrRow(agencije.ol_fax, index)" type="danger" icon="el-icon-delete"/>
							</el-input>
						</el-col>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
					<el-form-item :label="$t('agencije.telefon')">
						<el-button v-if="!agencije.ol_telefon.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_telefon)"/>
						<el-col v-else :key="index" v-for="(telefon, index) in agencije.ol_telefon">
							<el-input v-model="agencije.ol_telefon[index]" :maxLength="120" class="p--b-10">
								<el-button v-if="agencije.ol_telefon.length === index + 1" slot="prepend" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_telefon)"/>
								<el-button slot="append" @click="removeArrRow(agencije.ol_telefon, index)" type="danger" icon="el-icon-delete"/>
							</el-input>
						</el-col>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
					<el-form-item :label="$t('agencije.mail')">
						<el-button v-if="!agencije.ol_mail.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_mail)"/>
						<el-col v-else :key="index" v-for="(mail, index) in agencije.ol_mail">
							<el-input v-model="agencije.ol_mail[index]" :maxLength="120" class="p--b-10">
								<el-button v-if="agencije.ol_mail.length === index + 1" slot="prepend" type="success" icon="el-icon-plus" @click="addArrRow(agencije.ol_mail)"/>
								<el-button slot="append" @click="removeArrRow(agencije.ol_mail, index)" type="danger" icon="el-icon-delete"/>
							</el-input>
						</el-col>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>

		<el-card shadow="hover">
			<el-row>
				<el-col>
					<el-form-item class="labHeder">
						{{$t('agencije.kontaktLice')}}
						<el-button size="mini" type="success" icon="el-icon-plus" @click="addKontaktPodaci"></el-button>
					</el-form-item>
				</el-col>
				<el-col>
					<el-form-item>
						<el-table
							:data="agencije.kl_podaci"
							border
							stripe
							style="width: 100%">
						<el-table-column
								:label="$t('agencije.ime')">
							<template slot-scope="scope">
								<el-input v-model="scope.row.ime"/>
							</template>
						</el-table-column>
						<el-table-column
								:label="$t('agencije.mail')">
							<template slot-scope="scope">
								<el-button v-if="!scope.row.mail.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(scope.row.mail)"></el-button>
								<el-col v-else :key="scope.$index + 'mail' + indexMail" v-for="(mail, indexMail) in scope.row.mail">
									<el-input v-model="scope.row.mail[indexMail]" :maxLength="120" class="p--b-10">
										<el-button v-if="scope.row.mail.length === indexMail + 1" slot="prepend" icon="el-icon-plus" @click="addArrRow(scope.row.mail)"/>
										<el-button slot="append" icon="el-icon-delete" @click="removeArrRow(scope.row.mail, indexMail)" />
									</el-input>
								</el-col>
							</template>
						</el-table-column>
						<el-table-column
								:label="$t('agencije.telefon')">
							<template slot-scope="scope">
								<el-button v-if="!scope.row.telefon.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(scope.row.telefon)"></el-button>
								<el-col v-else :key="scope.$index + 'telefon' + indexTel" v-for="(telefon, indexTel) in scope.row.telefon">
									<el-input v-model="scope.row.telefon[indexTel]" :maxLength="120" class="p--b-10">
										<el-button v-if="scope.row.telefon.length === indexTel + 1" slot="prepend" icon="el-icon-plus" @click="addArrRow(scope.row.telefon)"/>
										<el-button slot="append" icon="el-icon-delete" @click="removeArrRow(scope.row.telefon, indexTel)" />
									</el-input>
								</el-col>
							</template>
						</el-table-column>
						<el-table-column
								align="center"
								width="100">
							<template slot-scope="scope">
								<el-button @click="removeArrRow(agencije.kl_podaci, scope.$index)" type="danger" icon="el-icon-delete"/>
							</template>
						</el-table-column>
					</el-table>
					</el-form-item>
				</el-col>
			</el-row>
		</el-card>


		<el-card shadow="hover">
			<el-row>
				<el-col>
					<el-form-item class="labHeder">
						{{$t('agencije.isturenaMesta')}}
						<el-button size="mini" type="success" icon="el-icon-plus" @click="addIsturenMesto"/>
					</el-form-item>
				</el-col>

				<el-col>
					<el-row>
						<el-col :key="indexMesto" v-for="(iz_mesto, indexMesto) in agencije.isturena_mesta">
							<el-card shadow="hover" class="m--b-20 bg--color-gray">
									<el-row>
										<el-col>
											<el-button style="float: right;" type="danger" icon="el-icon-delete" @click="removeArrRow(agencije.isturena_mesta, indexMesto)"/>
										</el-col>
									</el-row>
									<el-row>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.naziv')">
												<el-input v-model="iz_mesto.naziv" :maxLength="120"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.telefon')">
												<el-input v-model="iz_mesto.telefon" :maxLength="20"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.adresa')">
												<el-input v-model="iz_mesto.adresa" :maxLength="120"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.postanskiBroj')">
												<el-input v-model="iz_mesto.postanski_broj" :maxLength="15"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.mesto')">
												<el-input v-model="iz_mesto.mesto" :maxLength="45"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.opstina')">
												<el-input v-model="iz_mesto.opstina" :maxLength="45"/>
											</el-form-item>
										</el-col>
										<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="3">
											<el-form-item :label="$t('agencije.gpsKoordinate')">
												<el-input v-model="iz_mesto.gps_koordinate" :maxLength="20"/>
											</el-form-item>
										</el-col>
									</el-row>

									<el-row class="el-form-item">
										<el-col>
											<el-button type="success" icon="el-icon-plus" @click="addIsturenMestoKontaktLice(iz_mesto.kl_podaci)"></el-button>
										</el-col>
										<el-col>
											<el-table
													:data="iz_mesto.kl_podaci"
													border
													stripe
													style="width: 100%">
												<el-table-column
														:label="$t('agencije.ime')">
													<template slot-scope="scope">
														<el-input v-model="scope.row.ime"/>
													</template>
												</el-table-column>
												<el-table-column
														:label="$t('agencije.mail')">
													<template slot-scope="scope">
														<el-button v-if="!scope.row.mail.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(scope.row.mail)"></el-button>
														<el-col v-else :key="scope.$index + 'mail' + indexMail" v-for="(mail, indexMail) in scope.row.mail">
															<el-input v-model="scope.row.mail[indexMail]" :maxLength="120" class="p--b-10">
																<el-button v-if="scope.row.mail.length === indexMail + 1" slot="prepend" icon="el-icon-plus" @click="addArrRow(scope.row.mail)"/>
																<el-button slot="append" icon="el-icon-delete" @click="removeArrRow(scope.row.mail, indexMail)" />
															</el-input>
														</el-col>
													</template>
												</el-table-column>
												<el-table-column
														:label="$t('agencije.telefon')">
													<template slot-scope="scope">
														<el-button v-if="!scope.row.telefon.length" size="mini" type="success" icon="el-icon-plus" @click="addArrRow(scope.row.telefon)"></el-button>
														<el-col v-else :key="scope.$index + 'telefon' + indexTel" v-for="(telefon, indexTel) in scope.row.telefon">
															<el-input v-model="scope.row.telefon[indexTel]" :maxLength="120" class="p--b-10">
																<el-button v-if="scope.row.telefon.length === indexTel + 1" slot="prepend" icon="el-icon-plus" @click="addArrRow(scope.row.telefon)"/>
																<el-button slot="append" icon="el-icon-delete" @click="removeArrRow(scope.row.telefon, indexTel)" />
															</el-input>
														</el-col>
													</template>
												</el-table-column>
												<el-table-column
														align="center"
														width="100">
													<template slot-scope="scope">
														<el-button @click="removeArrRow(iz_mesto.kl_podaci, scope.$index)" type="danger" icon="el-icon-delete"/>
													</template>
												</el-table-column>
											</el-table>
										</el-col>
									</el-row>
							</el-card>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</el-card>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'agencije-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			agencije: {
				naziv: '',
				aktivno: 'DA',
				maticni_broj: null,
				pib: null,
				datum_pocetka_saradnje: null,
				sajt: '',
				telefon: '',
				procenat: 0,
				broj_licence: '',
				adresa: '',
				postanski_broj: null,
				mesto: '',
				opstina: '',
				gps_koordinate: '',
				banka: [
					// {
					// 	banka: '',
					// 	racun: ''
					// }
				],
				ol_ime: '',
				ol_mail: [],
				ol_telefon: [],
				ol_fax: [],
				kl_podaci: [
					// {
					// 	ime: '',
					// 	mail: [],
					// 	telefon: []
					// }
				],
				napomena: '',
				isturena_mesta: [
					{
						naziv: '',
						adresa: '',
						postanski_broj: null,
						mesto: '',
						opstina: '',
						gps_koordinate: '',
						telefon: '',
						kl_podaci: []
					}
				]
			},
			pickerOptions: {
				firstDayOfWeek: 1,
				disabledDate(date) {
					let d = new Date();
					d.setDate(d.getDate() - 1);
					return date < d;
				}
			},
			rules: {
				naziv: [{ required: true, max: 100, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('agencije.agencija')
		});

		this.$utils('stopLoadingAfter', [this.getAgencije()]);
	},
	methods: {
		getAgencije() {
			if (!this.formCreate) {
				return this.$get('agencije', { id: this.id }).then(data => {
					this.agencije = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.agencije);

				if (this.formCreate)
					this.$save('agencije', data);
				else
					this.$update('agencije', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		},
		addArrRow(arr) {
			arr.push('');
		},
		removeArrRow(arr, index) {
			arr.splice(index, 1);
		},
		addBanka() {
			this.agencije.banka.push({
				banka: '',
				racun: ''
			});
		},
		addKontaktPodaci() {
			this.agencije.kl_podaci.push({
				ime: '',
				mail: [],
				telefon: []
			});
		},
		addIsturenMesto() {
			this.agencije.isturena_mesta.push({
				naziv: '',
				adresa: '',
				postanski_broj: null,
				mesto: '',
				opstina: '',
				gps_koordinate: '',
				telefon: '',
				kl_podaci: [
					{
						ime: '',
						mail: [],
						telefon: []
					}
				]
			});
		},
		addIsturenMestoKontaktLice(arr) {
			arr.push({
				ime: '',
				mail: [],
				telefon: []
			});
		}
	},
	components: {}
};
</script>
